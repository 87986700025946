<template>
  <main-document :tabs="tabs"> </main-document>
</template>

<script>

export default {
  components: {
    MainDocument: () => import('@/components/main-document')
  },
  activated () {
    this.$store.commit('setModule', { name: this.$t('t.Processing') })
  },
  computed: {
    tabs () {
      return [{ typeName: '', documentType: 'Processing' }]
    }
  }
}
</script>
